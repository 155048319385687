import * as React from 'react';
import AuthWrapper from 'sharedComponents/AuthWrapper/AuthWrapper';
import { RouteComponentProps } from 'react-router';
import "./PdfPage.scss";
import { AdsService } from '../../../services/AdsService';
import ReactGA from 'react-ga';
import { Ad } from 'data/Ad';
import AdBarDynamic from 'sharedComponents/AdBarDynamic/AdBarDynamic';

interface State {
  allAds: Ad[];
}
class PdfPage extends React.Component<RouteComponentProps, State> {

  readonly state: State = {
    allAds: [],
  }

  async componentDidMount() {
    ReactGA.pageview(`/guides`);
    const allAds: Ad[] = await AdsService.getGeneralAds();
    this.setState({
      allAds,
    })
  }

  render(): JSX.Element {

    return (
      <AuthWrapper>

        <AdBarDynamic ads={this.state.allAds} {...this.props}/>

        <h1>South Dakota - Black Hills Trail Guide</h1>

        <div className={classNames.pdfContent}>
          <iframe
            title="Black Hills Trail Guide"
            className={classNames.pdfObject}
            src="https://player.flipsnack.com?hash=OUNCNUQ3NTlFOEMrdnVqZXVjaGJkYw=="
            width="100%" height="480" seamless={true} scrolling="no"
            frameBorder="0" allowFullScreen
            allow="autoplay; clipboard-read; clipboard-write"></iframe>
        </div>

        <h2>South Dakota - Black Hills Pick-Up Points</h2>

        <div className={classNames.sheetContent}>
          <object className={classNames.sheetObject}
                  data='https://docs.google.com/spreadsheets/d/1HNKw_vDNhTRu2Ga1FPiuKtt87-LCU-S3BdR-mkyju4k/preview?rm=minimal'>
            <iframe title='pick-up-points' src='https://docs.google.com/spreadsheets/d/1HNKw_vDNhTRu2Ga1FPiuKtt87-LCU-S3BdR-mkyju4k/preview?rm=minimal'></iframe>
          </object>
        </div>
      </AuthWrapper>
    );
  }

}

export default PdfPage;

const classNames = {
  pdfObject: 'pdf-object',
  pdfContent: 'pdf-content',
  sheetObject: 'sheet-object',
  sheetContent: 'sheet-content',
};
